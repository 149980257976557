import {Container, Row, Col, Image} from 'react-bootstrap';

import video from '../images/gradient.mp4';

function Video() {
  return (
    <video autoPlay loop muted playsInline className="bg-video">
            
    <source src={video} type="video/mp4" />

    </video>
  );
}

export default Video;