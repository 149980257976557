import Video from "./layout/Video.js";
import Header from "./layout/Header.js";
import MainButtons from "./layout/MainButtons.js";
import Footer from "./layout/Footer.js";

import { useEffect } from 'react';

const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

function App() {

  useEffect(hideLoader, []);

  return (
    <>
    <Video />
    <Header />
    <MainButtons />
    <Footer />
    </>
  );
}

export default App;
