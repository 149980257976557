import {Container, Row, Col, Image} from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiPlay, mdiInstagram, mdiEmailEditOutline } from '@mdi/js';

const iconSize = 1.1;


function MyIcon ({iconPath}) {

    return ( 
    <Icon className="icon" 
    path={iconPath} 
    size={iconSize} 
    color="white" 
    style={{position:"relative",top: "-2px", marginRight: "10px"}}
    />
    );
}

function MainButtons() {
  return (
    <Container className="p-3" style={{maxWidth:"640px"}}>
         <div className="mt-3 pt-2">
            <a href="https://soundcloud.com/jeffveda" target="_blank" style={{width: "100%"}} className="btn btn-round btn-custom" rel="noreferrer">
            <MyIcon iconPath={mdiPlay} />DJ Mixes ミックス
            </a>
        </div>
        <div className="mt-3 pt-2">
            <a href="https://www.instagram.com/jeffv.insta/" target="_blank" style={{width: "100%"}} className="btn btn-round btn-custom" rel="noreferrer">
            <MyIcon iconPath={mdiInstagram} />Follow on Instagram
            </a>
        </div>
        <div className="mt-3 pt-2">
                <button style={{width: "100%"}} className="btn btn-round btn-custom">
                <MyIcon iconPath={mdiEmailEditOutline} />Contact Me 
                </button>
        </div>
        
    </Container>
  );
}

export default MainButtons;