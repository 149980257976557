import {Container, Row, Col, Image} from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiInstagram, mdiSoundcloud, mdiFacebook } from '@mdi/js';

const iconSize = 1;


function MyListItem ({iconPath, link}) {

    return ( 
    <li className="list-inline-item">
        <a href={link} target="_blank">
        <Icon className="icon" 
        path={iconPath} 
        size={iconSize} 
        color="white"
        />
        </a>
    </li>
    );
}

function Footer() {
  return (
    <Container id="footer">
        <div class="col-md-12">
            <div className="mb-4 text-center">

                <div>
                    <ul class="about-social list-inline mt-5 text-center">

                    <MyListItem iconPath={mdiFacebook} link="https://www.facebook.com/iamjeffvader" />
                    <MyListItem iconPath={mdiInstagram} link="https://www.instagram.com/jeffv.insta" />
                    <MyListItem iconPath={mdiSoundcloud} link="https://soundcloud.com/jeffveda" />
                        
                    </ul>
                </div>

                <p class="custom-text-color-secondary footer-text mb-5 mt-3">It's all about the music!</p>

            </div>
        </div>
    </Container>
  );
}

export default Footer;