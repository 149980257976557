import {Container, Row, Col, Image} from 'react-bootstrap';

import React from 'react';
import profilepic from '../images/profile.jpg';

import Typed from 'typed.js';

function Header() {

  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        'I\'m a Bar and Club DJ in Fukuoka, Japan', 
        'I play trip-hop, nu-disco and deep house',
        'to tech-house, progressive and techno'],
      typeSpeed: 50,
      backDelay: 2000,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);


  return (
    <Container>
      <Row>
        <Col xs>
            <div className="mt-5">
            <Image src={profilepic} className="rounded-circle img-fluid mx-auto d-block"  alt="Jeff Veda" />
            </div>

            <div className="header-content text-center mx-auto">
                <h4 className="custom-text-color-primary header-text mb-2 mt-3">Jeff Veda</h4>
                <p className="custom-text-color-secondary subheader-text mb-2"><span ref={el} /></p>
            </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Header;